import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Home, linkResolver } from '@pwc-new-ventures/ui';
import Layout from '../layouts';

const postPrefix = 'stories';

export default function() {
  const { contentfulHomePage } = useStaticQuery(graphql`
    {
      contentfulHomePage(slug: { eq: "home" }) {
        metaTitle
        metaDescription
        image {
          ...ImageSharpMedium
        }
        heroCtaLink {
          slug
          internal {
            type
          }
        }
        heroCtaText
        heroDescription {
          json
        }
        heroHeadline
        heroImage {
          ...ImageSharpLarge
        }
        featureHeadline
        features {
          headline
          blurb {
            json
          }
          icon {
            ...ImageSharpOriginal
          }
        }
        navigators {
          ... on ContentfulNavigator {
            headline
            blurb {
              json
            }
            ctaText
            ctaLink {
              ...NavigatorCtaLink
            }
            image {
              ...ImageSharpMedium
            }
          }
          ... on ContentfulVideo {
            headline
            videoUrl
            blurb {
              json
            }
          }
        }
        clientsHeadline
        clientLogos {
          companyLogo {
            ...ImageSharpOriginal
          }
        }
        storiesHeadline
        featuredStories {
          headline
          ctaText
          slug
          redirectUrl
          internal {
            type
          }
          image {
            ...ImageSharpStoryCard
          }
        }
        closingNavigator {
          blurb {
            json
          }
          ctaText
          ctaLink {
            ...NavigatorCtaLink
          }
          headline
          image {
            ...ImageSharpOriginal
          }
        }
      }
    }
  `);

  const doc = {
    meta: {
      title: contentfulHomePage.metaTitle,
      description: contentfulHomePage.metaDescription,
      image:
        contentfulHomePage.image && contentfulHomePage.image.localFile.childImageSharp.fluid.src,
    },
    hero: {
      headline: contentfulHomePage.heroHeadline,
      body: contentfulHomePage.heroDescription,
      buttonText: contentfulHomePage.heroCtaText,
      buttonUrl:
        contentfulHomePage.heroCtaLink &&
        linkResolver({
          type: contentfulHomePage.heroCtaLink.internal.type,
          slug: contentfulHomePage.heroCtaLink.slug,
          postPrefix,
        }),
      image: contentfulHomePage.heroImage,
    },
    threeUp: {
      headline: contentfulHomePage.featureHeadline,
      cards: contentfulHomePage.features.map((feature) => ({
        headline: feature.headline,
        body: feature.blurb,
        image: feature.icon && feature.icon.localFile.publicURL,
      })),
      background: 'brand',
    },
    highlights: {
      cards: contentfulHomePage.navigators.map((navigator) => ({
        headline: navigator.headline,
        body: navigator.blurb,
        buttonText: navigator.ctaText,
        buttonUrl:
          navigator.ctaLink &&
          linkResolver({
            type: navigator.ctaLink[0].internal.type,
            slug: navigator.ctaLink[0].redirectUrl || navigator.ctaLink[0].slug,
            postPrefix,
          }),
        image: navigator.image,
        video: navigator.videoUrl,
        primary: true,
      })),
    },
    logos: {
      headline: contentfulHomePage.clientsHeadline,
      images:
        contentfulHomePage.clientLogos &&
        contentfulHomePage.clientLogos.map(({ companyLogo }) => companyLogo.localFile.publicURL),
      background: 'light-6',
    },
    stories: {
      headline: contentfulHomePage.storiesHeadline,
      cards: contentfulHomePage.featuredStories.map((card) => ({
        headline: card.headline,
        linkText: card.ctaText,
        linkUrl: linkResolver({
          type: card.internal.type,
          slug: card.redirectUrl || card.slug,
          postPrefix,
        }),
        image: card.image,
      })),
    },
    cta: {
      headline: contentfulHomePage.closingNavigator.headline,
      body: contentfulHomePage.closingNavigator.blurb,
      buttonText: contentfulHomePage.closingNavigator.ctaText,
      buttonUrl:
        contentfulHomePage.closingNavigator.ctaLink &&
        linkResolver({
          type: contentfulHomePage.closingNavigator.ctaLink[0].internal.type,
          slug:
            contentfulHomePage.closingNavigator.ctaLink[0].redirectUrl ||
            contentfulHomePage.closingNavigator.ctaLink[0].slug,
          postPrefix,
        }),
      image:
        contentfulHomePage.closingNavigator.image &&
        contentfulHomePage.closingNavigator.image.localFile.publicURL,
    },
  };

  return (
    <Layout title={doc.meta.title} description={doc.meta.description} image={doc.meta.image}>
      <Home doc={doc} Link={Link} />
    </Layout>
  );
}
